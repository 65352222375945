const $navbar = $('#main-nav'),
    $togglerMenu = $('#menu-toggle'),
    $togglerMenuContent = $('#navbarSupportedContent'),
    $togglerLanguage = $('#language-toggle'),
    $has_submenu = $('.has-submenu'),
    $togglerLanguageContent = $('#language-switch');

$togglerLanguage.on('click', function(e) {

        if($togglerMenuContent.hasClass('show')) {
            $togglerMenuContent.collapse('hide');
        }
});

$has_submenu.on('click', function(e) {
   e.preventDefault();
});

$togglerMenu.on('click', function(e) {

    if($togglerLanguageContent.hasClass('show')) {
        $togglerLanguageContent.collapse('hide');
    }

});

class Navigation {

    /**
     * Contains the navigation logic
     * @param {object} navigation jquery navigation object
     */

    constructor() {

        this.windowWidth = $(window).width();

        this.resize(); // resize listener


    }

    init(navigation, events) {
        this.$navigation = navigation;

        this.onEvent(events); //eventlistener
    }


    /**
     *  windowWidth refresh on resize
     */

    resize() {

        $(window).on('resize', () => {
            this.windowWidth = $(window).width();
        });

    }

    /**
     *  Fade in subnavigation on hover, touch
     *  @param {array} event which should watch
     */

    onEvent(event) {

        var eventsString = event.join(' ');

        this.$navigation.find(Navigation.options.menuItemClass).on(eventsString, (e) => {

            const $el = $(e.currentTarget);

            if(!Navigation.options.hasLink && $el.next(Navigation.options.subMenuClass)) {
                e.preventDefault();
            }


            $el.parent().parent().find('> li >' + Navigation.options.subMenuClass).removeClass('active');
            $el.next(Navigation.options.subMenuClass).addClass('active');


        });

    }
}
Navigation.options = {
    "collapse": 768,
    "subMenuClass": ".submenu",
    "menuItemClass": ".menu-item",
    "hasLink": "has-link"
};

var navigation = new Navigation();

navigation.init($togglerMenuContent, ['click', 'mouseenter']);
navigation.init($togglerLanguageContent, ['click']);
