(function ($) {
    let mainSearch = {
        logging: false,
        selector: {
            searchWrapperSelector: '.navbar--search',
            submitBtnSelector: '.button--submit',
            inputSelector: '.search-field',
            searchFormSelector: '.search__inner',
            wrapperActiveClass: 'search--active',
            navbarContainerWrapper: '.navbar-container'
        },
        init: function () {
            let me = this;
            me.searchWrapper = $(me.selector.searchWrapperSelector);
            me.navbarContainerWrapper = $(me.selector.navbarContainerWrapper);
            me.submitBtn = me.searchWrapper.find(me.selector.submitBtnSelector);
            me.input = me.searchWrapper.find(me.selector.inputSelector);
            me.form = me.searchWrapper.find(me.selector.searchFormSelector);
            me.addEventListener();
        },
        addEventListener: function () {
            let me = this;

            me.submitBtn.on('click', function (e) {
                me.clickSubmitButton(e);
            });
        },
        clickSubmitButton: function (e) {
            this.log(['clickSubmitButton', e]);
            e.preventDefault();

            if (this.isSearchFieldEmpty()) {
                this.navbarContainerWrapper.toggleClass(this.selector.wrapperActiveClass);
            } else {
                this.form.submit();
            }
        },
        isSearchFieldEmpty: function () {
            this.log(['isSearchFieldEmpty', this.input.val()]);

            return this.input.val() === '';
        },
        getViewportWidth: function () {
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    mainSearch.init();
}(jQuery));
