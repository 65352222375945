var $accordion = $('.accordion__filter');


$accordion.on('show.bs.collapse', function (evt) {


    $(evt.target).parents('.card').addClass('active');
  })

$accordion.on('hide.bs.collapse', function (evt) {

    $(evt.target).parents('.card').removeClass('active');
})

