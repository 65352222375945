
class ViewPort {

    /**
     *  Check Scrollposition and Element position
     */
    constructor() {
        this.windowHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
        this.$window = $(window);

        this.resizeEventListener();

    }

    /**
     *  update window height/width variables
     */
    resizeEventListener() {
        this.$window.on('resize', () => {
            this.windowHeight = window.innerHeight;
            this.innerWidth = window.innerWidth;
        })
    }

    /**
     *
     * @param el
     * @param offset
     * @param inview
     * @returns {boolean}
     */
    init(el, offset = 0, inview = true) {
        this.el = el;
        this.top = el.offsetTop;
        this.left = el.offsetLeft;
        this.width = el.offsetWidth;
        this.height = el.offsetHeight;


        this.offset = offset;

        if(inview) {
            return this.isInView();
        } else {
            return this.isHeighter();
        }
    }

    /**
     *
     * @returns {boolean}
     */
    isInView() {

        var el = this.el;
        var top = this.top;
        var left = el.offsetLeft;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            (top + this.offset) < (window.pageYOffset + this.windowHeight) &&
            left < (window.pageXOffset + this.innerWidth ) &&
            (top + this.height) > window.pageYOffset &&
            (left + this.width) > window.pageXOffset
        );
    }

    /**
     *
     * @returns {boolean}
     */
    isHeighter() {

        var el = this.el;
        var top = this.top;
        var left = el.offsetLeft;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            (top ) < window.pageYOffset + this.offset

        );
    }
}


class Counter {
    /**
     * check if counter are in viewport and starts
     */
    constructor() {
        this.$elements = $('.counter__animate');
        this.viewport = new ViewPort();
        this.counted = false;
        this.running = false;
        this.counter = 0;
        this.CounterInterval = function() {};

        this.checkForInView();
    }

    /**
     * check if counter in viewport
     */
    checkForInView() {
        this.$elements.each((key,value) => {

            if(this.viewport.init(value)) {

                this.startCounter($(value));
            }
        });

    }

    counterInterval() {

}

    loop(el, duration, end, decimalFormat) {

        let step = parseFloat(end)/parseFloat(duration) > 0.1 ? parseFloat(end)/parseFloat(duration) : 0.1;
        let counter = 0;
        this.running = true;

        let interval = 1;

        if(end/step <= 30) {
            interval = 100;
        }

        if(el.attr('data-reached') == 'true') {
            return false;
        }

        const mia = setInterval(() => {
            this.CounterInterval = this.loopInterval(el, step, end, decimalFormat);

            if(el.attr('data-reached') == 'true') {
                clearInterval(mia);
            }

        }, interval);

    }

    loopInterval(el, step, end, decimalFormat) {
        var counter = parseFloat(el.text().replace(decimalFormat, '.'));

        let result = parseFloat(counter) + step;
        el.text(result.toLocaleString('de-DE', { maximumFractionDigits: 1}));

        if(result + counter >= end) {
            el.attr('data-reached', 'true');
            el.text(parseFloat(end).toLocaleString('de-DE', { maximumFractionDigits: 1}));

        }

    }



    /**
     *  start counter
     * @param {object} el - jquery object
     */
    startCounter(el) {

        let countTo = el.attr('data-count');
        let decimalFormat = el.attr('data-decimal-format');
        let localeNumber = 0;

        if(!isNaN(countTo) && countTo.toString().indexOf('.') != -1) {
            if(this.running === false) {
                this.loop(el, 1000, countTo, decimalFormat);
            }

        }else {
            $({ countNum: el.text()}).animate({
                    countNum: countTo
                },
                {
                    duration: 2000,
                    easing:'linear',
                    step: function() {
                        el.text(Math.floor(this.countNum));
                    },
                    complete: function() {
                        el.text(this.countNum);
                    }

                });
        }


    }
}

class FadeInElements {
    /**
     * catch the fadeIn elements
     */
    constructor() {
        this.$elements = $('.front__para');
        this.$midElements = $('.front__para-mid');
        this.viewport = new ViewPort();

        this.checkForInView();
    }

    /**
     *  check for viewport and add/remove classes for animation
     */
    checkForInView() {
        this.$elements.each((key, value) => {
            if(this.viewport.init(value, $(window).height() / 3)) {
                $(value).remove('out');
                $(value).addClass('fly-in');
            }
        });

        this.$midElements.each((key, value) => {
            if(this.viewport.init(value,  $(window).height() / 3)) {
                $(value).remove('out');
                $(value).addClass('fly-in-mid');
            }
        })
    }
}

class Navigation {

    /**
     *  catch navbar
     */
    constructor() {
        this.$navbar = $('header .navbar');
        this.$subnav = $('header .navbar--sub');
        this.navdivider = $('.nav-divider');
        this.target = this.$navbar.attr('data-depends');
        this.targetEl = document.getElementById(this.target);
        this.navbarHeight =  this.$navbar.outerHeight() + 2 + this.$subnav.outerHeight();
        this.viewport = new ViewPort();

        this.checkForInverse();
    }

    /**
     *  check if a target element is in viewport and inverse the navbar
     * @returns {boolean}
     */

    checkForInverse() {
        if(!this.target && !this.targetEl) {
            return false;
        }


        if(this.viewport.init(this.targetEl, this.navbarHeight, false)) {
            this.$navbar.removeClass('inverse');
            this.$subnav.removeClass('inverse');
        } else {
            this.$navbar.addClass('inverse');
            this.$subnav.addClass('inverse');
        }
    }
}

const navigation = new Navigation();
const fadeIn = new FadeInElements();
const counter = new Counter();

$(window).ready(function() {
    navigation.checkForInverse();
    fadeIn.checkForInView();
    counter.checkForInView();
});

$(window).scroll(function() {
    navigation.checkForInverse();
    fadeIn.checkForInView();
    counter.checkForInView();
});

