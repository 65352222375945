(function ($) {
    let stateSelect = {
        logging: false,
        selector: {
            selectCountry: "select.country-select",
            selectState: "select.state-select",
        },
        init: function () {
            this.log("state-select.js loaded");

            this.addEventListener();
        },
        addEventListener: function () {
            this.log("addEventListener");

            $(this.selector.selectCountry).on('change', function (e) {
                stateSelect.onCountryChange(this, e);
            });

            $(this.selector.selectState).on('change', function (e) {
                stateSelect.onStateChange(this, e);
            });
        },
        onCountryChange: function (select, e) {
            this.log(["onCountryChange", select, e]);

            let $_selectState = $($(select).attr('data-state-select')),
                val = $(select).val(),
                inputID = $(select).attr('data-input-hidden');

            this.resetStateSelector($_selectState);
            this.toggleCountryStates($_selectState, val);

            $(inputID).val(val);
        },
        onStateChange: function (select, e) {
            this.log(["onStateChange", select, e]);

            let inputID = $(select).attr('data-input-hidden');

            $(inputID).val($(select).val());
        },
        toggleCountryStates: function ($_selectState, countryID) {
            let count = 0;
            $_selectState.children().each(function () {
                let $_option = $(this);
                if ($_option.attr('data-country') === countryID) {
                    $_option.attr('hidden', false);
                    count++;
                } else {
                    $_option.attr('hidden', true);
                }
            });

            if (count > 0) {
                $(this.selector.selectState).attr('hidden', false);
                $(this.selector.selectState).prop('required', true);
            } else {
                $(this.selector.selectState).attr('hidden', true);
                $(this.selector.selectState).prop('required', false);
            }
        },
        resetStateSelector: function ($_selectState) {
            $_selectState.prop('disabled', false);
            $_selectState.prop('selectedIndex', 0);
            $_selectState.find('option').attr('hidden', false);
        },
        setInputHiddenValue: function(select, val){
            $(select).closest('.select-group').find('input[type=hidden]').val(val);
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    stateSelect.init();
})(jQuery);
