
/*
 $('.collapse--content__main').css({ 'position': 'relative', 'left': '0', 'transition': '1s ease'});
 $('.collapse--content__result').css({ 'position': 'relative', 'left': '100%', 'transition': '1s ease'});

 $('.collapse--content__main').click(function() {
 $(this).css({ 'left': '-100%'});

 setTimeout(function(){
 $(this).css({ 'display': 'none'});
 $('.collapse--content__result').css({ 'left': '0', 'display': 'block'});

 }.bind(this), 1000);

 });
 */

$(".js-carousel").each(function(){
    var $carousel = $(this),
        $carouselContainer = $carousel.find(".js-carousel-container"),
        $carouselList = $carousel.find(".js-carousel-list"),
        $carouselItem = $carousel.find(".js-carousel-item"),
        $carouselButton = $carousel.find(".js-carousel-button"),
        $activeItem = $carousel.find(".active"),
        $navLink = $('.nav-link'),
        setItemWidth = function(){
            $carouselList.removeAttr("style");
            var curWidth = $($carouselItem[0]).outerWidth() * $carouselItem.length;
            $carouselList.css("width", curWidth);
        },
        slide = function(){
            var $button = $(this),
                dir = $button.data("dir"),
                curPos = parseInt($carouselList.css("left")) || 0,
                moveto = 0,
                containerWidth = $carouselContainer.outerWidth(),
                listWidth = $carouselList.outerWidth(),
                before = (curPos + containerWidth),
                after = listWidth + (curPos - containerWidth);
            if(dir=="next"){
                if(after < containerWidth) {
                    moveto =  curPos - after;
                } else {
                    moveto = curPos - containerWidth;
                }
                //$activeItem.next().next().addClass('active');
                //$activeItem.removeClass('active');
            } else {
                moveto = (before >= 0) ? 0 : curPos + containerWidth;
            }


            $carouselList.animate({
                left: moveto
            });
        };
    $(window).resize(function(){
        setItemWidth();
    });
    setItemWidth();

    $carouselButton.on("click", slide);
    $navLink.on("click", function() {
        setTimeout(function() {
            setItemWidth();
        });
    });
});