var $toolbox = $('.tool-box');

class Template {
    static fillTpl(e, targetContent, tpl) {

        let tmp = tpl.html();
        let content = targetContent || $(e.currentTarget).data('content');

        for (let [key, value] of Object.entries(content)) {

            let rex = new RegExp('{' + key + '}', 'g');

            if(key.length > 0 || key.media.length > 0) {

                if(value.image) {

                    tmp = Template.fillContentMedia(tmp,value,rex);
                } else {
                    tmp = Template.fillContent(tmp,value,rex);
                }

            } else {

                tmp = tmp.replace(rex, "");

            }
        }

        tmp = Template.clearTplfromUnused(tmp);

       return tmp;

    }

    static clearTplfromUnused(tmp) {
        let rex = new RegExp('{.*}', 'g');

        return tmp.replace(rex, "");

    }

    static fillContent( tmpl, value , rex ) {
        return tmpl.replace(rex,value);
    }

    static fillContentMedia(tmpl, value, rex) {

        if(value.image) {
            return tmpl.replace(rex, '<img class="preview-image" src="'+ value.image +'"');
        }

        return tmpl.replace(rex, '<img class="img-preview" src="'+ value.image +'"');

    }
}

export default class Toolbox {
    constructor($elements = $(), $modal = $('#tool-box-placeholder')) {
        this.$elements = $elements;

        this.$modal = $modal;

        this.eventListener();
    }

    init(tpl) {
        this.tpl = tpl;
    }

    eventListener() {

        if(!this.$elements) {
            return false;
        }

        this.$elements.on('click', (e) => {
            e.preventDefault();

            this.showModal(e);

        });

        $('.toolbox-backdrop').on('click', (e) => {
            this.hideModal($('.tool-box.active'));
        });

        this.$modal.on('click', '[data-action="close"]',(e) => {
           this.hideModal(this.$modal);
        });

        this.$modal.on('click', '[data-action="read-more"]',(e) => {
            this.showMore(e);
        });

        this.$modal.on('click', '[data-action="read-less"]',(e) => {
            this.showLess(e);
        });
    }

    fillTpl(e, targetContent) {

        let tmp = Template.fillTpl(e, targetContent, this.tpl);

        this.$modal.empty();
        this.$modal.append(tmp);

    }

    showModal(e, modalContent) {

        this.fillTpl(e, modalContent);
        this.$modal.addClass('active');
    }


    hideModal($modal) {

        $modal.removeClass('active');

        $modal.find('[data-action="read-more"]').fadeIn();
        $modal.find('.content-depending').fadeOut(300, function() {
            $toolbox.find('.preview-image').css({ 'opacity': '1'});
        });

    }

    showMore(e) {

        let event = e;

        this.$modal.find('.read-more').fadeOut(100, () => {

            this.$modal.find('.read-less').fadeIn();
            $(event.currentTarget).attr('data-action', 'read-less');

        });

        this.$modal.find('.content-depending').fadeIn();
        this.$modal.find('.preview-image').css({ 'opacity': '0'});
    }

    showLess(e) {
        let event = e;

        this.$modal.find('.read-less').fadeOut(100, () => {

            this.$modal.find('.read-more').fadeIn();
            $(event.currentTarget).attr('data-action', 'read-more');

        });

        this.$modal.find('.content-depending').fadeOut(300, function() {
            $toolbox.find('.preview-image').css({ 'opacity': '1'});
        });

    }
}

$('[data-action="toolbox"]').each(function() {
    var template = $(this).attr('data-template');

    if(template) {
        new Toolbox($('[data-action="toolbox"]')).init($(template));
    } else {
        new Toolbox($('[data-action="toolbox"]')).init($('#tool-box'));
    }
});
