import Loader from './loader';

(function ($, window) {
    var productSearchFilter = {
        logging: false,
        submitOnChange: true,
        paginationMode: 'form',
        selector: {
            wrapper: ".filter-content--wrapper",
            formName: 'product_filter_form',
            form: 'form[name="product_filter_form"]',
            productGroupInputs: '[name="product_filter_form[product_group]"]',
            rangeCls: 'range',
        },
        init: function () {
            this.log("productSearchFilter.js loaded");

            this.paginationMode = $('#listing').attr('data-pagination-mode');
            this.$_form = $(this.selector.form);
            this.addEventListener();
        },
        addEventListener: function () {
            this.log("addEventListener");

            this.$_form.find(this.selector.productGroupInputs).change(function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();

                productSearchFilter.$_form.find('input:text, input:password, input:file, input.select-range, select, textarea').val('');
                productSearchFilter.$_form.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');
                productSearchFilter.setCurrentPage(1);

                productSearchFilter.formSubmit();
            }.bind(this));

            this.$_form.find('select, input').change(function (e) {
                productSearchFilter.changeInput(this, e);
            });

            $('select.' + this.selector.rangeCls).change(function (e) {
                productSearchFilter.changeRange(this, e);
            });

            if (this.$_form.length && this.paginationMode === 'form') {
                $('.pagination .page-link').click(function (e) {
                    productSearchFilter.clickPaginationLink(this, e)
                });
            }

            this.$_form.find('button[type="submit"]').change(function (e) {
                e.preventDefault();
                productSearchFilter.formSubmit();
            });

            this.$_form.find('button[type="reset"]').click(function (e) {
                e.preventDefault();
                productSearchFilter.formReset();
            });
        },
        changeInput: function (input, e) {
            this.log(["changeInput", input, e]);
            this.setCurrentPage(1);

            if (!$(input).hasClass(this.selector.rangeCls) && this.submitOnChange) {
                productSearchFilter.formSubmit();
            }
        },
        changeRange: function (select, e) {
            this.log(["changeRange", select, e]);
            this.setCurrentPage(1);

            let $formGroup = $(select).closest('.form-group'),
                $selectMin = $formGroup.find('select[data-field="min"]'),
                $selectMax = $formGroup.find('select[data-field="max"]'),
                combinedValue = $selectMin.val() + '|' + $selectMax.val();

            if (combinedValue === '|') {
                combinedValue = '';
            }

            $formGroup.find('input[type="hidden"]').val(combinedValue);

            if (this.submitOnChange) {
                productSearchFilter.formSubmit();
            }
        },
        clickPaginationLink: function (link, e) {
            this.log(["clickPaginationLink", link, e]);
            e.preventDefault();

            let page = $(link).parent('.page-item').attr('data-page');
            this.setCurrentPage(page);

            productSearchFilter.formSubmit();
        },
        setCurrentPage: function(page){
            this.log(["setCurrentPage", page]);
            this.$_form.find('input[name="' + this.selector.formName + '[page]"]').val(page);
        },
        formSubmit: function () {
            this.log("formSubmit");
            this.$_form.find(':disabled').each(function(e) {
                $(this).removeAttr('disabled');
            });
            this.addLoader();
            // this.$_form.submit() funktioniert nicht immer. Danke jQuery
            this.$_form[0].submit();
        },
        formReset: function () {
            this.log("formReset");

            this.$_form.find('input:text, input:password, input:file, input.select-range, select, textarea, #product_filter_form_query').val('');
            this.$_form.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');
            this.$_form.find('#product_filter_form_product_group input:radio').eq(0).prop('checked', true);
            this.setCurrentPage(1);

            this.formSubmit();
        },
        addLoader: function(){
            Loader.load($(this.selector.wrapper));
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    productSearchFilter.init();
})(jQuery, window);
