import Loader from './loader';

(function($, window) {
  let loginBoxSelector = ".login-box";

  let _locale = $(loginBoxSelector).data("locale"),
    $_loginBox = $(loginBoxSelector),
    $_loginForm = $(loginBoxSelector + " #login-form"),
    $_emailInput = $(loginBoxSelector + " #login-mail"),
    $_passwordInput = $(loginBoxSelector + " #login-password"),
    $_submitButton = $(loginBoxSelector + " #login-submit"),
    $_errorText = $(loginBoxSelector + " #login-error");

  let loginBox = {
    init: function() {
      this.addEventListener();
    },
    addEventListener: function() {
      $(document).on('click', '[data-action="loginbox"]', function() {
        loginBox.toggleLoginLayerVisible();
      });

      $_loginForm.on("submit", function(event) {
        event.preventDefault();
        loginBox.doLogin();
      });
    },
    toggleLoginLayerVisible: function() {
      $_loginBox.toggleClass("active");
    },
    setFormActiveState: function(isActive) {
      $_emailInput.prop("disabled", !isActive);
      $_passwordInput.prop("disabled", !isActive);
      $_submitButton.prop("disabled", !isActive);
    },
    setErrorVisibleState: function($isVisible) {
      if ($isVisible) {
        $_errorText.css("display", "inline-block");
      } else {
        $_errorText.css("display", "none");
      }
    },
    doLogin: function() {
      loginBox.setFormActiveState(false);
      loginBox.setErrorVisibleState(false);

      Loader.load($_loginBox);

      $.post("/" + _locale + "/login", {
        email: $_emailInput.val(),
        password: $_passwordInput.val()
      }).always(function(response) {
        if (response.success) {
          window.location.reload();
        } else {
          loginBox.setErrorVisibleState(true);
          loginBox.setFormActiveState(true);

          Loader.clear();
        }
      });
    }
  };

  loginBox.init();
})(jQuery, window);
