var windowWidth = $('window').width();
var $demo = $('.product-demo');

if($demo.length > 0 ) {
    var $icons = $demo.find('.demo__icon');

    $icons.each(function() {
        $(this).css({
            'top': $(this).data('y') + '%',
            'left': $(this).data('x') + '%'
        })
    });

}