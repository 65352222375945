var $link = $('[data-toggle="tab"]');

$link.on('shown.bs.tab', activateLink);
$link.on('hidden.bs.tab', deactiveLink);

$link.on('hidden.bs.collapse', deactivateCollapse);
$link.on('shown.bs.collapse', activateCollapse);


function activateCollapse() {


    if(typeof $(this).attr('href') == 'undefined') {
        return false;
    }
    var target = $(this).attr('href').split('#');
    $('[role="tab"]').filter('[href="#'+target[1]+'"]').collapse('show');
}

function deactivateCollapse() {

    if(typeof $(this).attr('href') == 'undefined') {
        return false;
    }
    var target = $(this).attr('href').split('#');
    $('[role="tab"]').filter('[href="#'+target[1]+'"]').removeClass('active');
}

function deactiveLink() {

    if(typeof $(this).attr('href') == 'undefined') {
        return false;
    }
    var target = $(this).attr('href').split('#');
    $('[role="tab"]').filter('[href="#'+target[1]+'"]').removeClass('active');

}

function activateLink(e) {
    e.preventDefault();

    if(typeof this.href == 'undefined' && typeof this.dataset.hTarget == "undefined") {
        return false;
    }

    var href =  this.href ?  this.href.split('#') : null;
    var hHref =  this.dataset.hTarget ?  this.dataset.hTarget.split('#') : null;
    var target = href || hHref;

    $('[role="tab"]').filter('[href="#'+target[1]+'"]').tab('show');
    $('[role="tab"]').filter('[data-target="#'+target[1]+'"]').tab('show');

}
