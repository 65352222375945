export default class LoginState {
    static get() {
        const cookieMatch = document.cookie.match(/(^|; )isLoggedIn=(0|1)(;|$)/);

        if (cookieMatch !== null) {
            return jQuery.Deferred().resolve(parseInt(cookieMatch[2], 10) === 1);
        }

        const locale = /^\/([^\/]+)/.exec(location.pathname)[1];
        const url = '/{_locale}/login/isLoggedIn'.replace('{_locale}', locale);

        return jQuery.ajax(url, { timeout: 5000 }).then(function(response) {
            return response.isLoggedIn;
        }, function() {
            return false;
        });
    }
}

(function () {
    const deferredState = LoginState.get();

    jQuery(function() {
        deferredState.then(function(isLoggedIn) {
            jQuery('[data-show-when-loggedin="true"]').toggle(isLoggedIn);
            jQuery('[data-show-when-loggedin="false"]').toggle(!isLoggedIn);
        });
    });
})();