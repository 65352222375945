var $sameHeightElements = $('.same-height');
var heightGroups = {};

window.onload = function () {
    setHeight();
};

window.onresize = function(event) {
    setHeight();
};

function setHeight() {
    $sameHeightElements.height("auto");
    $sameHeightElements.each(function() {
        var group = $(this).data('group');
        var height = $(this).outerHeight();

        if(typeof heightGroups[group] === "undefined") {
            heightGroups[group] = {
                height: 0,
                elements: []
            }
        }
        heightGroups[group].height = parseInt(heightGroups[group].height) > height ? heightGroups[group].height : height;
        heightGroups[group].elements.push($(this))
    });

    var keys = Object.keys(heightGroups);

    for(var i = 0; i <= keys.length - 1; i++) {
        for(var u = 0; u <= heightGroups[keys[i]].elements.length - 1; u++ ) {
            $(heightGroups[keys[i]].elements[u]).outerHeight(heightGroups[keys[i]].height);
        }
    }
}
