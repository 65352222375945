;(function ($) {
    var productVariantsDropdown = {
        defaults: {
            dropdownToggleSelector: '.variants--headline',
            mainContainerSelector: '.productbox--variants',
            variantsWrapperSelector: '.variants--wrapper',
            sliderSelector: '.variants--content',
            toggleClass: 'is--collapsed',
            slideSpeed: 150
        },
        slickConfig: {
            infinite: false,
            dots: false,
            arrows: false,
            speed: 200,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    }
                }
            ]
        },
        init: function () {
            const me = this;
            me.$mainContainer = $(me.defaults.mainContainerSelector);
            me.initSlick(me.slickConfig);
        },
        initSlick: function (args) {
            const me = this,
                variantsSelector = '.variant',
                hasSliderClass = 'has--slider',
                initClass = 'slider-initialized';

            me.$mainContainer.each(function () {
                const $this = $(this),
                      $slider = $this.find(me.defaults.sliderSelector),
                      $toggle = $this.find(me.defaults.dropdownToggleSelector),
                      $toggleTarget = $this.find(me.defaults.variantsWrapperSelector),
                      variantsAmount = $slider.children(variantsSelector).length;

                if ( variantsAmount > args.slidesToShow) {
                    $slider.slick(args);
                    $slider.nextAll('.slick-prev').on('click', function () {
                         const $clickEl = $(this);
                        $clickEl.prevAll(me.defaults.sliderSelector).slick('slickPrev');
                    });
                    $slider.nextAll('.slick-next').on('click', function () {
                        const $clickEl = $(this);
                        $clickEl.prevAll(me.defaults.sliderSelector).slick('slickNext');
                    });
                    $this.addClass(hasSliderClass);
                } else {
                    $slider.addClass('has--'+variantsAmount+'-variants');
                }

                $toggle.on('click', function (e) {
                    e.preventDefault();
                    $toggle.toggleClass(me.defaults.toggleClass);
                    $toggleTarget.slideToggle(me.defaults.slideSpeed, function () {
                        if ($this.hasClass(hasSliderClass) && !$toggleTarget.hasClass(initClass) ) {
                            $slider.slick('setPosition');
                            $toggleTarget.addClass(initClass);
                        }
                    });
                })
            });
        }
    };

    productVariantsDropdown.init();

})(jQuery);
