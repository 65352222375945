const Slider = require("bootstrap-slider");
import Loader from './loader';

(function($, window) {
  var quickFinder = {
    logging: false,
    selector: {
      wrapper: ".quick-finder",
      btnGroup: " .btn-group",
      typeContainer: ".select-type-container",
      optionsContainer: ".select-options-container",
      buttonCategory: ".select-category",
      dropdown: ".dropdown",
      dropdownToggle: ".dropdown-toggle",
      dropdownToggleLabel: ".dropdown-toggle > span",
      dropdownItem: ".dropdown-item",
      dropdownDataName: "data-name",
      dropdownDataTarget: "data-target",
      dropdownCloseBtn: ".btn-close",
      activeClass: "active",
      rangeSlider: ".range-slider",
      slider: ".slider",
      sliderHandle: ".slider-handle"
    },
    init: function() {
      this.log("quick-finder.js loaded");

      this.addEventListener();
      this.createSlider();
    },
    addEventListener: function() {
      this.log("addEventListener");

      $(this.selector.buttonCategory).click(function(e) {
        quickFinder.clickSelectTypeButton(this, e);
      });

      $(this.selector.wrapper)
        .find(this.selector.dropdownToggle)
        .click(function(e) {
          quickFinder.toggleDropdownMenu(
            $(this).attr(quickFinder.selector.dropdownDataTarget),
            e
          );
        });

      $(this.selector.wrapper)
        .find(this.selector.dropdownItem)
        .click(function(e) {
          e.preventDefault();
          quickFinder.clickDropdownItem(this, e);
        });

      $(this.selector.wrapper)
        .find(this.selector.dropdown + " > " + this.selector.dropdownCloseBtn)
        .click(function(e) {
          e.preventDefault();
          quickFinder.clickCloseBtn(this, e);
        });

      $("body").on("click", function(event) {
        if (
          !$(event.target).is(quickFinder.selector.dropdownItem) &&
          !$(event.target).is(quickFinder.selector.dropdownToggle) &&
          !$(event.target).is(quickFinder.selector.dropdownToggleLabel) &&
          !$(event.target).is(quickFinder.selector.sliderHandle) &&
          !$(event.target).is(quickFinder.selector.slider) &&
          !$(event.target).is(quickFinder.selector.rangeSlider)
        ) {
          $(quickFinder.selector.wrapper)
            .find(quickFinder.selector.dropdown)
            .removeClass("show");
        }
      });

      $(this.selector.wrapper)
        .find('button[type="submit"]')
        .click(function(e) {
          e.preventDefault();
          quickFinder.formSubmit($(this).closest("form"), e);

          Loader.load($('html, body'));
        });

      $(this.selector.wrapper)
        .find('[data-provide="slider"]')
        .on("slide", function(e) {
          quickFinder.slide(e);
        });
    },
    clickSelectTypeButton: function(btn, e) {
      this.log(["clickSelectTypeButton", btn, e]);

      e.preventDefault();

      this.removeSelectCategoryBgClasses(btn);
      this.removeOptionsContainerClasses();

      $(this.selector.wrapper)
        .find(this.selector.btnGroup)
        .addClass("bg-" + $(btn).data("position"));
      $(this.selector.wrapper)
        .find(this.selector.optionsContainer)
        .addClass("show-" + $(btn).data("position"));
      $(this.selector.wrapper)
        .find('input[name="attributes[0][value]"]')
        .val($(btn).data("type"));
    },
    removeSelectCategoryBgClasses: function(btn) {
      this.log(["removeSelectCategoryBgClasses", btn]);

      $(btn)
        .parent(this.selector.btnGroup)
        .removeClass("bg-first");
      $(btn)
        .parent(this.selector.btnGroup)
        .removeClass("bg-second");
    },
    removeOptionsContainerClasses: function() {
      this.log("removeOptionsContainerClasses");

      $(this.selector.wrapper)
        .find(this.selector.optionsContainer)
        .removeClass("show-first");
      $(this.selector.wrapper)
        .find(this.selector.optionsContainer)
        .removeClass("show-second");
    },
    toggleDropdownMenu: function(dropdownName, e) {
      this.log(["toggleDropdownMenu", dropdownName, e]);

      let $dropdown = $(this.selector.wrapper).find(
        this.selector.dropdown +
          "[" +
          this.selector.dropdownDataName +
          '="' +
          dropdownName +
          '"]'
      );

      $.each($(this.selector.wrapper).find(this.selector.dropdown), function(
        key,
        dropdown
      ) {
        if (
          $(dropdown).attr(quickFinder.selector.dropdownDataName) !==
          dropdownName
        ) {
          $(dropdown).removeClass("show");
        }
      });

      if (!$dropdown.hasClass("show")) {
        $dropdown.addClass("show");
      } else if (
        $(e.target).attr(this.selector.dropdownDataTarget) !== dropdownName
      ) {
        $dropdown.removeClass("show");
      }
    },
    clickCloseBtn: function(closeBtn, e) {
      this.log(["closeDropdownMenu", closeBtn, e]);

      this.toggleDropdownMenu(
        $(closeBtn)
          .parent()
          .attr(this.selector.dropdownDataName),
        e
      );
    },
    clickDropdownItem: function(item, e) {
      this.log(["clickDropdownItem", item, e]);

      let $dropdown = $(item).closest(this.selector.dropdown),
        dropdownName = $dropdown.attr(this.selector.dropdownDataName),
        $dropdownToggle = $(
          this.selector.dropdownToggle +
            "[" +
            this.selector.dropdownDataTarget +
            '="' +
            dropdownName +
            '"]'
        );

      $dropdownToggle.text($(item).text());
      $dropdown.find(this.selector.dropdownItem).each(function(k, i) {
        $(i).removeClass(quickFinder.selector.activeClass);
      });

      $(item).addClass(this.selector.activeClass);

      this.toggleDropdownMenu(
        $dropdown.attr(this.selector.dropdownDataName),
        e
      );
      this.setInputValue(item);
    },
    setInputValue: function(item) {
      this.log(["setInputValue", item]);

      let value = $(item).attr("data-attribute-value"),
        $dropdown = $(item).closest(this.selector.dropdown),
        key = $(item).attr("data-key");

      $dropdown.find('input[name="attributes[' + key + '][value]"]').val(value);
    },
    formSubmit: function(form, e) {
      this.log(["formSubmit", form, e]);

      let formAction = $(form).attr("action"),
        type = $(form)
          .find('input[name="attributes[0][value]"]')
          .val(),
        attributes = this.getFormAttributes(form, type);

      Loader.load($(this.selector.wrapper));

      window.location.href = formAction + "?" + attributes.join("&");
    },
    getFormAttributes: function(form, type) {
      this.log(["getFormAttributes", form, type]);

      let $inputs = $(form).find(
          'input[name^="attributes[0]"], input[data-type="' + type + '"]'
        ),
        attributes = [];

      $.each($inputs, function(k, input) {
        if ($(input).attr("data-input-type") === "field") {
          let $inputField = $(input),
            $inputValue = $inputField.next(),
            $inputCondition = $inputValue.next(),
            searchCriteria = "";

          if ($inputValue.val().trim() !== "") {
            searchCriteria =
              $inputField.attr("name") +
              "=" +
              $inputField.val() +
              "&" +
              $inputValue.attr("name") +
              "=" +
              $inputValue.val();

            if (
              $inputCondition !== undefined &&
              $inputCondition.val().trim() !== ""
            ) {
              searchCriteria +=
                "&" +
                $inputCondition.attr("name") +
                "=" +
                $inputCondition.val();
            }

            attributes.push(searchCriteria);
          }
        }
      });

      return attributes;
    },
    createSlider: function() {
      this.log(["createSlider"]);

      $(this.selector.wrapper)
        .find(".range-slider > input")
        .each(function(key, slider) {
          let mySlider = new Slider(slider, {
            formatter: function(value) {
              if (Array.isArray(value)) {
                return (
                  value[0] +
                  " - " +
                  value[1] +
                  " " +
                  $(slider).data("slider-unit")
                );
              } else {
                return value;
              }
            }
          });

          mySlider.on("slide", function(sliderValue) {
            quickFinder.slide(mySlider, sliderValue[0], sliderValue[1]);
          });
        });
    },
    slide: function(slider, start, end) {
      this.log(["slide", slider, start, end]);

      let $input = slider.$element,
        $dropdown = $input.closest(this.selector.dropdown),
        $inputRange = $dropdown.find('[data-input-type="value"]');

      $inputRange.val(start + "|" + end);
    },
    log: function(msg) {
      if (this.logging) {
        console.log(msg);
      }
    }
  };

  quickFinder.init();
})(jQuery, window);
