$(document).ready(function(){

    var $carousel = $('.owl-carousel');

    $carousel.each(function() {

            let $ca = $(this);

            window.addEventListener("tentefilter", (evt) => {
                var category = evt.detail.categories;
                var foundIndex;

                $ca.find('.owl-item').not('.cloned').each((index,el) => {

                    if($(el).children('.owl-content').data('category').indexOf(category) >= 0) {
                        foundIndex = index;
                    }
                });

                if(!isNaN(foundIndex)) {
                    $ca.trigger('to.owl.carousel', [foundIndex]);
                    $ca.trigger('stop.owl.autoplay');
                }

            }, false);


            $(this).owlCarousel( {
            thumbs: true,
            thumbsPrerendered: true,
            loop: true,
            nav: true,
            autoplay: ($(this).data('slide') == true ? true : false),
            smartSpeed: 500,
            navText: [''],
            responsive: {
                0: {
                    items: $(this).data('items-mobile') || 1
                },
                768: {
                    items: $(this).data('items-tablet') || 1
                },
                1024: {
                    items: $(this).data('items') || 1
                }
            }

        });

    });

    $carousel.on('drag.owl.carousel', function(event) {
        $(this).find('.carousel-swipe').remove();
    });

});