/**
 *
 * @param {string} data-video-target    - video div as css selector
 * @param {string} data-video-action    - pause/play stops or start the video
 * @param {string} data-video           - id from video
 *
 * @template    <div id="fullpage-video" class="yt-video" data-video="fTG3emqnDrQ"></div>
 * @template    <button data-video-action="pause" data-video-target="#fullpagevideo">Stop Video</button>
 *
 * @see https://developers.google.com/youtube/js_api_reference?hl=de
 *
 */

var player = {};
var options = {
    'api_url': 'https://www.youtube.com/iframe_api',
    'event_play': '[data-video-action="play"]',
    'event_pause': '[data-video-action="pause"]'
};


var startVideo = function(target) {

    var $targetEl = $('#' + target);
    var cleanTarget =  target.replace(/\W/g, '');

    var videoId =  $targetEl.attr('data-video');


    if(player[cleanTarget]) {

        player[cleanTarget].playVideo();

    } else {

        player[cleanTarget] = new YT.Player(target, {
            height: '1920',
            width: '1080',
            videoId: videoId,
            playerVars: {
                'playsinline': 0,
                'autoplay': 0,
                'controls': 1,
                'showinfo': 0,
                'enablejsapi': 1,
                fs: 1,
                rel: 0
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': function(event) {
                    onPlayerChange(event, cleanTarget, $targetEl);
                }
            }
        });
    }

};

var loadSources = function(target) {

    if (typeof YT === 'undefined') {
        $.getScript(options.api_url);

        window.onYouTubePlayerAPIReady = function(){
            startVideo(target);
        };

    } else {
        startVideo(target);
    }

};

var stopVideo = function(target) {
    var $targetEl = $(target);
    var cleanTarget =  target.replace(/\W/g, '');

    if (typeof YT !== 'undefined') {
        player[cleanTarget].stopVideo();
    }

};

var onPlayerReady = function(event) {

};

var onPlayerChange = function(event, cleanTarget, targetEl) {

    var state = player[cleanTarget].getPlayerState();

    if(state === 3 || state === 1) {
        onPlayed(targetEl);
    } else{
        //onPaused(targetEl);
    }

};

var onPaused = function(target) {

    var helper = $('#' +target.attr('id')).parents().find('.video-swipe-helper, .media--addon');
    helper.fadeIn();
};

var onPlayed = function(target) {
    var helper = $('#' +target.attr('id')).parents().find('.video-swipe-helper');

    helper.fadeOut();
};

$(document).on('click', options.event_play, function() {
    var $el = $(this);
    var target = $el.attr('data-video-target');

    loadSources(target);
});

$(document).on('click', options.event_pause, function() {
    var $el = $(this);
    var target = $el.attr('data-video-target');

    stopVideo(target);
});

$(document).ready(function() {

    $('[data-video-target]').each(function() {
        loadSources($(this).attr('data-video-target'));
    });

});