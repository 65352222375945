(function ($) {
    let pdfDownloads = {
        logging: false,
        url: "https://media.tente.com/download/",
        fadeDuration: "fast",
        selector: {
            wrapper: ".pdf-download-wrapper",
            pdfContainer: ".pdf-download",
            inputFilter: 'input.filter-toggle',
            selectLanguage: 'select.language',
            buttonDownload: 'button.download'
        },
        init: function () {
            this.log("pdf-downloads.js loaded");

            this.addEventListener();
        },
        addEventListener: function () {
            this.log("addEventListener");

            $(this.selector.wrapper).find(this.selector.inputFilter).change(function (e) {
                pdfDownloads.filter(e, this);
            });

            $(this.selector.wrapper).find(this.selector.selectLanguage).change(function (e) {
                pdfDownloads.changeLanguage(e, this);
            });

            $(this.selector.wrapper).find(this.selector.buttonDownload).click(function (e) {
                pdfDownloads.openPdfFile(pdfDownloads.getPdfContainerByElement(this));
            });
        },
        filter: function (e, input) {
            this.log(["filter", e, input]);

            let category = $(input).attr('data-category'),
                $visibleContainers = $(this.selector.pdfContainer + '[data-category="' + category + '"]'),
                $hiddenContainers = $(this.selector.pdfContainer + '[data-category!="' + category + '"]');

            if (category === "all") {
                $visibleContainers = $(this.selector.pdfContainer);
                $hiddenContainers = null;
            }

            if ($hiddenContainers) {
                $hiddenContainers.hide();
            }

            $visibleContainers.fadeIn(this.fadeDuration);
        },
        changeLanguage: function(e, select){
            this.log(["changeLanguage", e, select]);

            let $selectedOption = $(select).find('option:selected'),
                image = $selectedOption.attr('data-image');

            this.setPdfImage($selectedOption.closest(this.selector.pdfContainer), image);
        },
        setPdfImage: function(pdfContainer, image){
            this.log(["setPdfImage", pdfContainer, image]);

            $(pdfContainer).find('img').attr('src', image);
        },
        openPdfFile: function(pdfContainer){
            this.log(["setPdfImage", pdfContainer]);

            let $selectedOption = $(pdfContainer).find(this.selector.selectLanguage + ' option:selected'),
                filename = $selectedOption.attr('data-filename'),
                fileUrl = this.url + filename,
                win = window.open(fileUrl, '_blank');

            if (win) {
                win.focus();
            }
        },
        getPdfContainerByElement: function(element){
            return $(element).closest(pdfDownloads.selector.pdfContainer);
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    pdfDownloads.init();
})(jQuery);
