$('.input-counter').on('click', 'button', function() {
    var $parent = $(this).parents('.input-counter');
    var $input = $parent.find('input');
    var $btn = $parent.find('button');
    var dataType = $(this).data('type');
    var value = parseInt($input.attr('value'));
    var action = dataType == 'plus' ? 'plus' : 'minus';
    var min = parseInt($input.attr('min'));
    var max = parseInt($input.attr('max'));

    $btn.each(function() {
        $(this).prop('disabled', true);
    });

    if(action == 'plus' && value + 1 <= max) {
        $input.attr('value', value + 1);
    }

   if(action == 'minus' && value - 1 >= min) {
       $input.attr('value', value - 1);
   }
   $btn.each(function() {
       $(this).prop('disabled', false);
   });

});