$(document).on('click', '.action', function (evt) {
    evt.preventDefault();

    if($(this).data('target') == 'wishlist') {
        $('.wishlist--icon').addClass('filled');
    }

    if($(this).data('target') == 'cart') {
        $('.cart--icon').addClass('filled');
    }


});