var slider = document.getElementsByClassName('ui-slider');



if(slider) {
    for(var i = 0; i < slider.length; i++) {

        var min = slider[i].getAttribute('min');
        var max = slider[i].getAttribute('max');
        var steps = [ ((max - min) / 5), ((max - min) / 4) , ((max - min) / 3)];
        var start = (max - min) / 2;

        var config = {
            start: parseInt(start),
            connect: "lower",
            step: 1,
            snap: true,
            range: {
                min: parseInt(min),
                max: parseInt(max)
            }
        };

        steps.map((variable) => {
            //config.range[(variable / max) * 100 + '%'] = variable;
        });

        noUiSlider.create(slider[i], config);
    }
}


$(document).ready(function() {

    for(var i = 0; i < slider.length; i++) {
        slider[i].noUiSlider.on('update', function( values, handle ) {

            var $el = $(this.target);
            var $indicator = $el.parent().find('.indicator');


            var $parent = $el.parents('.range--track');
            var $parentWidth = $parent.width();
            var newPlace = 0;
            var offset = 0;


            var width = $parentWidth;
            var newPoint = ((parseInt(values) ) / ($el.attr("max") - $el.attr("min")) );

            offset = 3;

            if (newPoint < 0) { newPlace = 0; }
            else if (newPoint > 1) { newPlace = width; }
            else { newPlace = width * newPoint + offset; offset -= newPoint; }

            if(width == 100 || width == 0) {
                newPlace = "48%";
            }

            $el.parent().find('.amount').text(parseInt(values));

            $indicator
                .css({
                    left: newPlace,
                    marginLeft: offset + "%"
                })
        });

    }

});