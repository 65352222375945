(function ($, document) {
    let cadDownload = {
        logging: false,
        selector: {
            cadDownloadBtn: "button#cad-download",
            dropdown: "#cad-files.dropdown",
            errorMessage: "#error_message",
            formCadData: "#form_cad_data",
            loadingSpinner: "#loader",
        },
        $_form: null,
        format: null,
        init: function () {
            this.log("cad-download loaded");

            this.$_form = $(this.selector.formCadData);
            this.notFoundMessage = this.$_form.attr('data-not-found-message');

            this.addEventListener();
            this.addErrorMessageContainer();
        },
        addEventListener: function () {
            this.log("addEventListener");

            $(this.selector.cadDownloadBtn).click(function (e) {
                cadDownload.clickDownloadButton(e);
            });

            $(this.selector.dropdown).find('.dropdown-item').click(function (e) {
                cadDownload.clickDropDownItem(e);
            });
        },
        clickDropDownItem: function (e) {
            this.log(["clickDropDownItem", e]);

            this.format = $(e.currentTarget).text();
            this.setDropdownToggleText(this.format);

            this.$_form.find('input[name="format"]').val(this.format);
        },
        clickDownloadButton: function (e) {
            this.log(["clickDownloadButton", e]);

            if (this.format !== null) {
                this.getFile();
            }
        },
        setDropdownToggleText: function (text) {
            this.log(["setDropdownToggleText", text]);

            $(this.selector.dropdown).find('.dropdown-toggle').text(text);
        },
        addErrorMessageContainer: function () {
            this.log(["addErrorMessageContainer"]);

            this.$errorMessageContainer = $('<div>', {
                class: 'panel-heading',
                id: this.selector.errorMessage.substr(1),
                text: '',
            }).insertBefore(this.selector.cadDownloadBtn).css({
                'color': '#636363',
                'background-color': '#f5f5f5',
                'border-color': '#ddd',
                'margin-top': '10px',
                'padding': '5px 10px',
            }).hide();
        },
        addErrorMessage: function (message) {
            console.log("got error while downloading CAD file: " + message);
            this.$errorMessageContainer.text(message);
            this.$errorMessageContainer.show();
        },
        toggleDownloadBtn: function (show = true) {
            $(this.selector.cadDownloadBtn).toggle(show);
        },
        toggleLoadingSpinner: function (show) {
            $(this.selector.loadingSpinner).toggle(show);
        },
        toggleErrorMessageContainer: function (show = true) {
            $(this.selector.errorMessage).toggle(show);
        },
        getFile: function () {
            this.$errorMessageContainer.hide();

            let notFoundMessage = this.notFoundMessage;

            let path = $(this.selector.cadDownloadBtn).attr('data-link');
            let url = window.location.origin + path + '?' + this.$_form.serialize(); // NOSONAR

            let xhr = new XMLHttpRequest();

            xhr.addEventListener('error', function (event) {
                cadDownload.addErrorMessage('Failed to download file');
            })

            xhr.addEventListener('load', function (event) {
                if (xhr.status !== 200) {
                    cadDownload.addErrorMessage(
                        xhr.status === 404
                            ? notFoundMessage
                            : 'Failed to download file'
                    );
                    return;
                }

                // Automatic detection in Chrome does not work here, so we parse the header manually.
                let fileName = xhr.getResponseHeader('Content-Disposition')
                    .replace(/^.*;\s*filename="([^"]+)".*$/, "$1");

                let blobUrl = URL.createObjectURL(xhr.response);

                let blobLink = document.createElement('a');
                blobLink.download = fileName;
                blobLink.href = blobUrl;
                blobLink.click();

                URL.revokeObjectURL(blobUrl);
            });

            xhr.responseType = 'blob';
            xhr.open('GET', url, true);
            xhr.send();
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    $(window).on('tente:cart_and_cad:loaded', function () {
        cadDownload.init();
    })
})(jQuery, document);
