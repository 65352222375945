var windowWidth = $(window).width();
var $mobileCollapseEl = $('[data-mobile-collapse]');
var collapseItemsSel = '.cards';
var collapseButton = '.accordion--header';
var init = true;

if($mobileCollapseEl.length > 0) {

    $(window).resize(function() {
        checkCollapse();
    });

    $(document).ready(function() {
        checkCollapse();
    });

    mobileHandling();

}

function checkCollapse() {

    var currentWindowWidth = $(window).width();

    if(currentWindowWidth === windowWidth && init === false) {
       return false;
    }

    $mobileCollapseEl.each(function() {

        var mobileBreakpoint = parseInt($(this).data('mobile-collapse'));

        if((windowWidth < mobileBreakpoint && currentWindowWidth < mobileBreakpoint ||
            windowWidth > mobileBreakpoint && currentWindowWidth > mobileBreakpoint) &&
            init === false) {

            return true;
        }

        if(currentWindowWidth > mobileBreakpoint) {
            $(this).find(collapseItemsSel).addClass('active').fadeIn();
        } else {
            $(this).find(collapseItemsSel).removeClass('active').fadeOut();
        }

    });

    init = false;
    windowWidth = currentWindowWidth;
}

function mobileHandling() {

    $mobileCollapseEl.each(function() {

        var mobileBreakpoint = parseInt($(this).data('mobile-collapse'));


       if(windowWidth < mobileBreakpoint) {

           $(this).find(collapseButton).click(function() {

               var $el = $(this);

               if($el.hasClass('active') ) {
                   $el.removeClass('active');
                   $el.parent().find(collapseItemsSel).slideUp(300);

               } else {
                   $el.addClass('active');
                   $el.parent().find(collapseItemsSel).show('slow');
               }

           })

       }
    });


}