var source = [
    "A523PJI050B10-11x20 DOM45",
    "A523PJI050B10-11x20 DOM45",
    "5923XGI065B10-11x20 DOM27",
    "5923UJI065B10-11x20 DOM27",
    "2477PJP100P30-11",
    "2475PJP050P40 TRG",
    "1470PAO050P30-11",
    "2470PIP150R47-21,5-24",
    "2970YGO050P40"
];


var AutoComplete = require('../../lib/jquery-auto-complete/jquery.auto-complete'),
    autocompleteContainer = [];

// var autocompleteContainer = [];
var elem = document.querySelectorAll('.input--autocomplete');

if (elem) {
    for(var i = 0, c = elem.length ; i < c ; i++) {
        autocompleteContainer.push(
            new AutoComplete({
                selector: elem[i],
                source: function(term, suggest) {
                    term = term.toLowerCase();
                    // @todo: make this dynamic
                    var choices = source;
                    var matches = [];
                    for (i=0; i<choices.length; i++)
                        if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
                    suggest(matches);
                },
                minChars: 2
            })
        )
    }
}
