'use strict';

var $toolbox = $('.tool-box__shop');

$('.box__icon-shop').click(function() {
    $toolbox.addClass('active');
})

$toolbox.on('click', '[data-action="read-more"]', function() {
    $(this).text('read-less');
    $(this).attr('data-action', 'read-less');
    $toolbox.find('.content-depending').fadeIn();
    $toolbox.find('.preview-image').css({ 'opacity': '0'});
});

$toolbox.on('click', '[data-action="read-less"]', function() {
    $(this).text('read-more');
    $(this).attr('data-action', 'read-more');
    $toolbox.find('.content-depending').fadeOut();
    $toolbox.find('.preview-image').css({ 'opacity': '1'});
});



$toolbox.on('click', '[data-action="close"]', function() {
    $toolbox.removeClass('active');

    $('[data-action="read-more"]').fadeIn();
    $toolbox.find('.content-depending').fadeOut();
    $toolbox.find('.preview-image').css({ 'opacity': '1'});
});