(function ($) {
    var form = {
        selector: {
            form: "form",
            errorCls: "error",
            elGroups: [
                ".input-group",
                ".select-group",
                ".checkbox-group",
            ],
        },
        init: function () {
            const forms = $(this.selector.form);

            forms.submit(form.onSubmitForm);
            forms.find('select, textarea, input').change((e) => form.toggleError(e.target));
        },
        onSubmitForm: function (event) {
            const inputs = Array.apply(null, event.target.querySelectorAll('input, select, textarea'));

            inputs.forEach((input) => form.toggleError(input));

            if (!event.target.checkValidity()) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
        },
        toggleError: function (el) {
            $(el).closest(this.selector.elGroups.join(',')).toggleClass(form.selector.errorCls, !el.checkValidity());
        },
    };

    form.init();
})(jQuery);
