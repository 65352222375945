;(function ($, window, document, undefined) {

    var $slideUpelement = $('.slider__inner-down');
    var $slideDownelement = $('.slider__inner-up');

    var $sliderUp = $('.slider__inner-up').children();
    var $sliderDown = $('.slider__inner-down').children();


    var count = 0;

    setInterval(function () {


        $slideUpelement.find('.out').removeClass('out');

        if($slideUpelement.find('.active').next().length > 0) {
            $slideUpelement.find('.active').removeClass('active').addClass('out').next().addClass('active');
        } else {
            $slideUpelement.find('.active').removeClass('active').addClass('out');
            $('.slider__inner-down .slider__element:first-child').addClass('active');
        }

        $slideDownelement.find('.out').removeClass('out');

        if( $slideDownelement.find('.active').next().length > 0) {
            $slideDownelement.find('.active').removeClass('active').addClass('out').next().addClass('active');
        } else {
            $slideDownelement.find('.active').removeClass('active').addClass('out');
            $('.slider__inner-up .slider__element:first-child').addClass('active');
        }


    }, 4000);



})(window.Zepto || window.jQuery, window, document);