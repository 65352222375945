
class Cookie {
    /**
     *  Handle Cookie logical
     *  @see https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
     */

    constructor() {


    }

    getItem(sKey) {
        if (!sKey) { return null; }
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    };

    setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure, sSamesite) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                    /*
                    Note: Despite officially defined in RFC 6265, the use of `max-age` is not compatible with any
                    version of Internet Explorer, Edge and some mobile browsers. Therefore passing a number to
                    the end parameter might not work as expected. A possible solution might be to convert the the
                    relative time to an absolute time. For instance, replacing the previous line with:
                    */
                    /*
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; expires=" + (new Date(vEnd * 1e3 + Date.now())).toUTCString();
                    */
                    break;
                case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                case Date:
                    sExpires = "; expires=" + vEnd.toUTCString();
                    break;
            }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue)
            + sExpires
            + (sDomain ? "; domain=" + sDomain : "")
            + (sPath ? "; path=" + sPath : "")
            + (bSecure ? "; secure" : "")
            + (sSamesite ? "; samesite=" + sSamesite : "");
        return true;
    }

    removeItem(sKey, sPath, sDomain) {
        if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    }

    hasItem(sKey) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }

    keys() {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
    }

}

class CookieWarning {
    constructor() {
        this.$element = $('.cookie-warning');
        this.cookie = new Cookie();
        this.scrollPosition =  document.body.scrollTop || document.documentElement.scrollTop;

        this.check();
        this.scroll();
        this.clickEvent();

        this.scrollEventListener = function() {};
    }

    /**
     *  check if cookie is set
     */
    check() {

        this.scrollPosition =  document.body.scrollTop || document.documentElement.scrollTop;

        if((this.scrollPosition > this.$element.height() || $(window).width() <= 992  )&& !this.cookie.hasItem('te-cookie-accept')) {
            this.$element.fadeIn();
        } else {
            this.$element.fadeOut();
        }
    }

    parseUrl(url) {

        const local = new RegExp('localhost');
        const ipv4UrlRegex = new RegExp('([0-9]{1,3}\.){3}[0-9]{1,3}');
        const ipv6UrlRegex = new RegExp('(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}');

        if(ipv4UrlRegex.test(url) || ipv6UrlRegex.test(url) || local.test(url)) {
            return url;
        }

        var urlSplitted = url.split('.');

        return urlSplitted[urlSplitted.length-2] + '.' + urlSplitted[urlSplitted.length-1];
    }

    clickEvent() {
        this.$element.find('.close').click(() => {
            this.$element.fadeOut();

            this.cookie.setItem(
                'te-cookie-accept',
                'true',
                31536e3,
                '/',
                this.parseUrl(window.location.hostname),
                true,
                'lax'
            );
            $(window).off('scroll', this.scrollEventListener);
        });

    }

    scroll() {
        $(window).on('scroll', () => this.scrollEventListener = this.check());
    }

    accept() {

    }
}

new CookieWarning();