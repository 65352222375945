
class LoadMore {
  constructor() {
    this.init();
  }

  init() {
    this.eventListener();
  }
  eventListener() {

    $(document).on("click", LoadMore.options.actionEl, ( e ) => {
        this.loadMore(e );
    })
  }
  loadMore( e ){
    var $context = this.findContext( e );

    $context.find('.' +LoadMore.options.moreEl + ':lt(' + LoadMore.options.pagination+ ')').removeClass(LoadMore.options.moreEl);

    this.checkMoreBtn( $context );
  }

  checkMoreBtn( $context ) {

    if($context.find('.' + LoadMore.options.moreEl).length <= 0 ) {
      $(LoadMore.options.actionEl).addClass('inactive');
    }

  }

  findContext( e ) {
    return  $($(e.currentTarget).data("target"));
  }

}

LoadMore.options = {
    moreEl: "filtered--inactive",
    actionEl: "[data-action='load-more']",
    pagination: 25,

};

new LoadMore();