import SmoothScroll from './smoothscroll';

(function ($, window) {
    let scroll = {
        logging: false,
        headerHeight: 0,
        selector: {
            cls: ".scroll",
        },
        init: function () {
            this.log("scroll.js loaded");

            this.addEventListener();
            this.setMainOffset();
        },
        addEventListener: function () {
            this.log("addEventListener");

            $(this.selector.cls).click(function (e) {
                scroll.to(this, e);
            });

            $(window).resize(function (e) {
                scroll.setMainOffset(e);
            });
        },
        to: function (el, e) {
            this.log(["to", el, e]);

            if ($($(el).attr('data-scroll')).length > 0) {
                scroll.toTarget(el);
            } else if ($(el).attr('data-scroll-next')) {
                scroll.toNext(el);
            } else {
                scroll.toTop();
            }
        },
        toTarget: function (el) {
            this.log(["toTarget", el, scroll.headerOffset(el)]);
            SmoothScroll.scroll($($(el).attr('data-scroll')).offset().top, scroll.headerOffset(el));
        },
        toNext: function (el) {
            this.log(["toNext", el]);

            let $container = $(el).parents('.module');
            let $nextContainer = $container.next();

            SmoothScroll.scroll($nextContainer.offset().top, scroll.headerOffset(el));
        },
        toTop: function () {
            this.log(["toTop"]);

            SmoothScroll.scroll(0);
        },
        headerOffset: function (el) {
            const attrOffset = $(el).data('scroll-offset');
            let offset = $('header.is--sticky').length ? $('header.is--sticky').height() - $('.main-top').height() : $('header').height();
            if (attrOffset > 0) {
                offset += attrOffset;
            }
            return offset
        },
        setMainOffset: function (e) {
            this.log(["setMainOffset", e]);
            this.headerHeight = $('header').height();

            $('main').css('margin-top', this.headerHeight);
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    scroll.init();
})(jQuery, window);
