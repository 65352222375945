export default class SmoothScroll {
    static scroll( position , offset = 0) {
        if (isNaN(position)) {
            return false;
        }

        $('html, body').animate({
            scrollTop: position - offset
        }, 1000);
    }
}