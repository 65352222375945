var Slider = require("bootstrap-slider");

(function($, window) {
    var history = {
        logging: false,
        selector: {
            historyBtn: '[data-action="history-back"]',
        },
        init: function() {
            this.log("history.js loaded");

            this.addEventListener();
        },
        addEventListener: function() {
            this.log("addEventListener");

            $(this.selector.historyBtn).click(function(e) {
                history.back(e);
            });
        },
        back: function(e) {
            this.log(["back", e]);
            this.log([window.history]);

            window.history.back();
        },
        log: function(msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    history.init();
})(jQuery, window);
