import Toolbox from './toolbox-placeholder';

const map = document.getElementById('map')

if (map &&
    map.dataset.key &&
    map.dataset.locations &&
    map.dataset.locations !== '[]') {
    const {key, locations, marker} = map.dataset

    window.onGoogleMapsLoaded = function() {
        window.currentMaps = new Maps(
            {
                id: map.id,
                popup: true,
                marker: marker
            },
            JSON.parse(locations)
        );
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=onGoogleMapsLoaded`
    document.body.appendChild(script);
}

export default class Maps {

    /**
     * Create a google maps card with markers and eventlistener
     * @param {string}  id - ID from object where the maps should be injected
     * @param {boolean} popup - if true opens a popup by clicking the marker
     * @param {number}  zoom - google maps zoomlevel settings
     * @param {string}  marker image path
     * @param {object}  locations - locations object with coords {"title": "testtitle", "coords": { "lat": 25.21, "lng": "2.5"}
     */
    constructor({id = 'map', popup = false, zoom = 3, marker = ""}, locations) {
        this.popup = popup;
        this.zoom = zoom;
        this.markerPath = marker;
        this.marker = [];
        this.locations = locations;
        this.mapElement = document.getElementById(id);
        this.map = {};
        this.toolbox = new Toolbox();
        this.activeCategories = [];
        this.toolbox.init($('#tool-box-map'));


        if (this.mapElement) {
            this.init();
            this.listener();
        }
    }

    listener() {
        $('.toolbox-event').on('click', (e) => {
            e.preventDefault();

            var markerIndex = $(e.currentTarget).data('marker-index') || 0;

            google.maps.event.trigger(this.marker[markerIndex], 'click');

        });

        this.onEvent();
        this.zoomListener();
    }

    onEvent() {
        window.addEventListener("tentefilter", (evt) => {
            this.activeCategories = evt.detail.categories;
            this.setMarkerWithCategories();
        }, false);

    }

    setMarkerWithCategories() {

        for (let i = 0; i < this.marker.length; i++) {
            if (this.marker[i].categories.indexOf(this.activeCategories) >= 0 || !this.activeCategories) {
                this.marker[i].setMap(this.map);
            } else {
                this.marker[i].setMap(null);
            }
        }
    }

    zoomListener() {
        this.map.addListener('zoom_changed', () => {

            for (let i = 0; i < this.marker.length; i++) {
                this.marker[i].setMap(null);
            }

            this.setMarkerWithCategories();

        });

    }

    /**
     *  init map and markers
     */
    init() {
        this.map = new google.maps.Map(this.mapElement, this.getMapOptions());

        this.setMarkers();
    }

    /**
     *  Get Mapoptions
     * @returns {object}
     */
    getMapOptions() {
        let lat = this.locations[0].coords.lat;
        let lng = this.locations[0].coords.lng;

        if (this.locations.length > 1) {
            lat = 42.6825747;
            lng = 3.7966636;
        }

        return {
            zoom: this.zoom,
            center: new google.maps.LatLng(lat, lng),
            styles: Maps.styleArray
        }
    }

    /**
     *  Get Style options for google marker
     * @returns {object}
     */
    getMarkerStyles(zoom = 1) {

        let zoomStrength = 1;

        if (1 - zoom !== 0) {
            zoomStrength = (1 - zoom) / 2 + zoom;
        }


        return {
            url: this.markerPath ? this.markerPath : '../../images/sample/map_icon.png',
            size: new google.maps.Size(59, 59),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(12.5 * zoomStrength, 25 * zoomStrength),
            scaledSize: new google.maps.Size(25 * zoomStrength, 25 * zoomStrength)
        }
    }

    getZoomStrength() {
        const zoomCurrent = this.map.getZoom();
        const zoomBreakpoint = 5;
        let zoom = 1;

        if (parseInt(zoomCurrent) < zoomBreakpoint) {
            zoom = zoomCurrent >= 0 ? (parseInt(zoomCurrent) / parseInt(zoomBreakpoint)) : 1;
        }

        return parseFloat(zoom);

    }

    /**
     *  Set markers with eventlistener
     */
    setMarkers() {

        let zoomStrength = this.getZoomStrength();

        for (let i = 0; i < this.locations.length; i++) {
            var location = this.locations[i];

            this.marker[i] = new google.maps.Marker({
                position: {lat: location.coords.lat, lng: location.coords.lng},
                map: this.map,
                icon: this.getMarkerStyles(zoomStrength),
                shape: Maps.shape,
                title: location.title,
                categories: location.categories,
                content: {"content": location.info}
            });

            this.marker[i].addListener('click', (e) => {

                if (this.popup === true) {
                    this.popupMap(e, this.marker[i]);
                }

            });

        }
    }

    /**
     * Open toolbox and insert map
     * @param [event} e
     * @param {object} marker
     */
    popupMap(e, marker) {

        let content = marker.content.content ? marker.content.content : {};
        this.toolbox.showModal(e, content);

        let map = new Maps({
            id: 'tool-box--map',
            popup: false,
            zoom: 14,
            marker: this.markerPath
        }, [{"coords": {"lat": marker.position.lat(), "lng": marker.position.lng()}}]);
    }

}

Maps.shape = {
    coords: [1, 1, 1, 25, 25, 25, 25, 1],
    type: 'poly'
};

/**
 *  Map Style
 */
Maps.styleArray = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#123d6d"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#123d6d"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#123d6d"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#123d6d"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e3f3fd"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#123d6d"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#d2eafa"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a8d7f1"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#123d6d"
            }
        ]
    }
];
