var $filter = $('[data-action="filter"]');


class SelectFilter {
    constructor() {
        this.elements =  $('[data-action="filter"]');

        if(this.elements.length > 0)  {
            this.eventListener();
        }
    }

    eventListener() {

        $(this.elements).on("change", (el) => {

            var $el = $(el.currentTarget);
            if($el.is('select')) {
                this.filter($el.data('target'), this.getSelectValue($el));
            }

        });

    }

    filter(target, category) {

        var targetElements = $(target).find('.filtered--content__boxes');

        targetElements.each((index, el) => {

            if($(el).data('category').indexOf(category) >= 0 || !category) {
                $(el).removeClass('fadeOut');
                $(el).addClass('fadeIn');
            } else {
                $(el).addClass('fadeOut');
                $(el).removeClass('fadeIn');
            }
        });
    }

    getSelectValue( $el ) {
        return $el.val();
    }

}

new SelectFilter();
