
$('.stretch-slider').each(function() {
    var $active = $(this).find(".stretch-slider__item.active");
    $(this).find('.c-1').css({
        'maxWidth': $active.width() * 5/12
    })
});

$('.stretch-slider__item').on('click mouseenter', function() {


    $(this).parents(".stretch-slider").find(".stretch-slider__item").removeClass("active");
    $(this).addClass("active");
});