(function ($) {
    var customCollapse = {
        defaults: {
            menuSwitchSelector: '.navbar-toggler',
            languageSwitchSelector: '.navigation-secondary--toggler',
            indicatorClass: 'is--collapsed'
        },
        init: function () {
            const me = this;
            me.$menu = $(me.defaults.menuSwitchSelector);
            me.$language = $(me.defaults.languageSwitchSelector);
            me.addEventListener();
        },
        addEventListener: function () {
            const me = this;
            me.$language.on('click', function(e) {
                e.preventDefault();
                const $this = $(this),
                      $wrapper = $($this.attr('target')),
                      $neighbour = $(me.$menu.attr('target')),
                      neighbourCollapsed = $neighbour.hasClass(me.defaults.indicatorClass);
                if(neighbourCollapsed){
                    $neighbour.slideUp().removeClass(me.defaults.indicatorClass);
                }
                $wrapper.toggleClass(me.defaults.indicatorClass).slideToggle();
            });
            me.$menu.on('click', function(e) {
                e.preventDefault();
                const $this = $(this),
                    $wrapper = $($this.attr('target')),
                    $neighbour = $(me.$language.attr('target')),
                    neighbourCollapsed = $neighbour.hasClass(me.defaults.indicatorClass);
                if(neighbourCollapsed) {
                    $neighbour.slideUp().removeClass(me.defaults.indicatorClass);
                }
                $wrapper.toggleClass(me.defaults.indicatorClass).slideToggle();
            });
        }
    };
    customCollapse.init();
}(jQuery));
