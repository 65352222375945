import "core-js/stable";

// Global Vendors
require("../../node_modules/nouislider/distribute/nouislider.css");
require("../../node_modules/owl.carousel/dist/assets/owl.carousel.css");
require("../../node_modules/bootstrap/dist/js/bootstrap.js");
require("../../node_modules/bootstrap-slider/dist/bootstrap-slider.js");

// Local Tente Styles
require("../scss/tente.scss");

// local Javascript modules
require("./app/login-state");
require("./app/sticky-navigation");
require("./app/customevent");
require("./app/accordion");
require("./app/accordion");
require("./app/action");
require("./app/autocomplete");
require("./app/cookie");
require("./app/fade");
require("./app/filter");
require("./app/glossar");
// require("./app/height");
require("./app/inview");
require("./app/main-search.js");
require("./app/main");
require("./app/mainnav");
require("./app/mobileCollapse");
require("./app/number");
require("./app/owlcarousel");
require("./app/parallax");
require("./app/range");
require("./app/smoothscroll");
require("./app/scroll");
require("./app/tabCollapse");
require("./app/tabExtension");
require("./app/toolbox-shop");
require("./app/toolbox");
require("./app/video");
// require("./app/search");
require("./app/map");
require("./app/toolbox-placeholder");
require("./app/productDemo");
require("./app/av-show");
require("./app/solutionSlider");
require("./app/stretch-slider");
require("./app/modalSlide");
require("./app/headerslider");
require('./app/form');
require("./app/loadmore");
require("./app/events-filter");
require("./app/same-height");
require("./app/touch-detection");
require("./app/quick-finder");
require("./app/pagination");
require("./app/login-box");
require("./app/modalPreview");
require("./app/state-select");
require("./app/pdf-downloads");
require("./app/productSearchFilter");
require("./app/custom-collapse");
require("./app/inquiry");
require("./app/history");
require("./app/slick-config");
require("./app/cad-download");
require("./app/loader");
