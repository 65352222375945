(function ($) {
    var stickyNavigation = {
        logging: false,
        defaults: {
            headSelector: 'header[data-sticky="true"]',
            stickyClass: 'is--sticky',
            hasStickyHeaderClass: 'module--offset-y',
            siblingSelector: ['main', 'div'],
            breadCrumpClass: 'has--breadcrump',
            entry: 50
        },
        init: function () {
            const me = this;
            me.$head = $(me.defaults.headSelector);
            if ( !me.$head.length ) return;
            for (var i = 0; i < me.defaults.siblingSelector.length; i++) {
                if (me.$head.nextAll(me.defaults.siblingSelector[i]).eq(0).length) {
                    $sibling = me.$head.nextAll(me.defaults.siblingSelector[i]).eq(0);
                    const classList = me.$head.hasClass(me.defaults.breadCrumpClass) ?  me.defaults.hasStickyHeaderClass+' '+me.defaults.breadCrumpClass : me.defaults.hasStickyHeaderClass;
                    $sibling.addClass(classList);
                    break;
                }
            }
            me.isActive = false;
            me.wasActive = false;
            me.addEventListener();
        },
        addEventListener: function () {
            const me = this,
                  $window = $(window);
            $window.on('touchmove resize scroll', function(){
                me.scrollHandler()
            });
        },
        scrollHandler: function () {
            const me = this;
            me.isActive = window.pageYOffset > me.defaults.entry;
            if (me.isActive !== me.wasActive) {
                me.isActive ? (
                    me.$head.addClass(me.defaults.stickyClass)
                ) : (
                    me.$head.removeClass(me.defaults.stickyClass)
                );
                me.wasActive = me.isActive;
            }
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };
    stickyNavigation.init();
}(jQuery));
