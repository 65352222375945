(function ($) {
    let inquiry = {
        logging: false,
        selector: {
            wrapper: ".inquirylist",
            btnDelete: "button.delete",
            btnCounter: "button.counter",
            inputQuantity: "input[name='product-quantity']",
        },
        init: function () {
            this.log("inquiry.js loaded");

            this.addEventListener();
        },
        addEventListener: function () {
            this.log("addEventListener");

            $(this.selector.wrapper).find(this.selector.btnDelete).click(function (e) {
                inquiry.clickDeleteButton(this, e);
            });

            $(this.selector.wrapper).find(this.selector.btnCounter).click(function (e) {
                inquiry.clickInputCounter(this, e);
            });

            $(this.selector.wrapper).find(this.selector.inputQuantity).change(function (e) {
                inquiry.updateProduct($(this).closest('form'), $(this).val(), e);
            });
        },
        clickDeleteButton: function (button, e) {
            this.log(["clickDeleteButton", button, e]);

            let $form = $(button).closest('form'),
                $inputQuantity = $form.find(this.selector.inputQuantity);

            $inputQuantity.val(0);
            $form.submit();
        },
        clickInputCounter: function (button, e) {
            this.log(["clickInputCounter", button, e]);

            let $form = $(button).closest('form'),
                $inputQuantity = $form.find(this.selector.inputQuantity),
                countType = $(button).attr('data-type'),
                val = parseInt($inputQuantity.val());

            if (countType === 'minus') {
                val = val - 1;
            } else if (countType === 'plus') {
                val = val + 1;
            }

            $inputQuantity.val(val);

            this.updateProduct($form, val ,e);
        },
        updateProduct: function(form, val, e) {
            this.log(["updateProduct", form, val, e]);

            $.ajax({
                method: "POST",
                url: form.attr('action'),
                data: {
                    'product-quantity': val
                },
                dataType: 'json',
            })
            .done(function( response ) {
                inquiry.log(response);
            });
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    inquiry.init();
})(jQuery);
