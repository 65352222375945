(function ($, document) {
    var modalPreview = {
        logging: false,
        selector: {
            toggle: '[data-toggle="modal-preview"]',
        },
        init: function () {
            this.log("modal.js loaded");

            this.addEventListener();
        },
        addEventListener: function () {
            this.log("addEventListener");

            $(document).on("click", this.selector.toggle, function (e) {
                modalPreview.openPreview(this, e);
            });
        },
        openPreview: function (toggleButton, e) {
            this.log(["addEventListener", toggleButton, e]);

            let $modal = $($(toggleButton).attr('data-target')),
                bigImagePath = $(toggleButton).attr('data-big-image'),
                img = $('<img>', {
                    'src': bigImagePath
                });

            $modal.find('.modal-body').html(img);
            $modal.modal('show');
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    modalPreview.init();
})(jQuery, document);
