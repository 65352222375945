const $filteredWrapper = $('.filtered-wrapper');


class Filter {
    constructor() {
        this.category = "";
        this.windowWidth = $(window).width();
        this.$wrapper = $();
        this.$filterElements = $();


    }

    init(wrapper) {
        this.$wrapper = wrapper;
        this.$activeNav = $();
        this.activeCategory = "";

        this.clickEvents();
        this.resizeEvent();
        this.onInit();

    }

    resizeEvent() {
        $(window).resize(function() {
            const frontier = this.windowWidth > 992 ? true : false;

            this.windowWidth = $(window).width();

            if(this.windowWidth > 992) {
                $filteredWrapper.each(function() {
                    $(this).find(Filter.options.filterCollapseContent).remove();
                });
            } else if (frontier) {
                $filteredWrapper.find(Filter.options.filterNav + '.active').removeClass('active');
            }

        });
    }

    onInit() {
        this.$wrapper.each((index, el) => {

            this.$activeNav = $(el).find(Filter.options.filterNav + '.active');
            this.activeCategory = this.$activeNav.data('category');
            const $filteredContent = this.$wrapper.find(Filter.options.filterContent);
            this.$filterElements = $filteredContent.find(Filter.options.filteredElements);

            this.filter();

        })
    }

    clickEvents() {

        this.$wrapper.on('click', Filter.options.filterNav, (evt) => {
            evt.preventDefault();

            this.$activeNav = $(evt.currentTarget);
            this.activeCategory = this.$activeNav.data('category');
            const $filteredContent = this.$wrapper.find(Filter.options.filterContent);
            this.$filterElements = $filteredContent.find(Filter.options.filteredElements);

            this.filter();

        })
    }

    filter() {

        var evt = new CustomEvent("tentefilter", {detail: { "categories": this.activeCategory }});
        window.dispatchEvent(evt);

        if(this.windowWidth > 992) {

            $filteredWrapper.find(Filter.options.filterNav + '.active').removeClass('active');
            this.$activeNav.addClass('active');

            this.$filterElements.each((index, el) => {

                if($(el).data('category').indexOf(this.activeCategory) >= 0 || !this.activeCategory) {
                    $(el).removeClass('fadeOut');
                    $(el).addClass('fadeIn');
                } else {
                    $(el).addClass('fadeOut');
                    $(el).removeClass('fadeIn');
                }
            });
        } else {

            this.$activeNav.parent(Filter.options.filterEl).find(Filter.options.filterCollapseContent).remove();

            if(this.$activeNav.hasClass('active')) {
                this.$activeNav.removeClass('active');

            } else {

                $filteredWrapper.find(Filter.options.filterNav + '.active').removeClass('active');
                this.$activeNav.addClass('active');


                this.$activeNav.after('<div class="'+ Filter.options.filterCollapseContent.replace('.', '') +'"></div>');

                this.$filterElements.each((index, el) => {

                    if($(el).data('category').indexOf(this.activeCategory) >= 0 || !this.activeCategory) {

                        const $target = $(el).clone();
                        $(Filter.options.filterCollapseContent).append($target);
                    }
                });
            }
        }
    }
}

Filter.options = {
    filterNav: '.navbar__filter--el',
    filterContent: '.filtered--content',
    filterInactive: 'filtered--inactive',
    filteredElements: '.filter--el',
    filterEl: '.filter--elements',
    filterCollapseContent: '.filter--collapse-content'
};

const filter = new Filter();

$filteredWrapper.each(function() {
    filter.init($(this));
});
