'use strict';

var tabContent = document.getElementsByClassName('tab-content--glossar');

for (var i = 0; i < tabContent.length; i++) {

    var links = tabContent[i].getElementsByClassName('more');

    for (var k = 0; k < links.length; k++) {
        links[k].addEventListener("click", toggleStatus);
    }
}

function toggleStatus() {
    var parent = findParent(this, 'glossar-collapse');

    parent.classList.toggle('active');

}

function findParent(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}