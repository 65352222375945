import Loader from './loader';

(function ($) {
    var pagination = {
        logging: true,
        selector: {
            pageNavigationSelector: '.pagination',
            pageBtnSelector: '.page-item',
            pageLinkSelector: '.page-link',
            activeClass: 'active',
            firstPageClass: 'first-page',
            lastPageClass: 'last-page',
            listingContentSelector: '.content.listing',
        },
        init: function () {
            var me = this;
            me.pageNavigation = $(me.selector.pageNavigationSelector);
            me.pageLink = $(me.selector.pageLinkSelector);
            me.listingContent = $(me.selector.listingContentSelector);
            me.addEventListener();
        },
        addEventListener: function () {
            var me = this;
            me.pageLink.click(function (e) {
                if($(this).parent().data('page') !== $(me.selector.pageBtnSelector+'.'+me.selector.activeClass).eq(0).data('page')){
                    me.setActiveState($(this).parent().data('page'));
                }else{
                    e.preventDefault();
                }
            });
        },
        setActiveState: function(pageId){
            var me = this;
            me.pageNavigation.each(function () {
                $(this).children(me.selector.pageBtnSelector+'.'+me.selector.activeClass).removeClass(me.selector.activeClass);
            });
            me.pageNavigation.each(function () {
                $(this).children(me.selector.pageBtnSelector).each(function () {
                    let isFirst = $(this).children().hasClass(me.selector.firstPageClass);
                    let isLast = $(this).children().hasClass(me.selector.lastPageClass);
                    if($(this).data('page') == pageId && !isFirst && !isLast ) {
                        $(this).addClass(me.selector.activeClass);
                    }
                });
            });

            Loader.load(me.listingContent);
        },
        log: function (msg) {
            if (this.logging) {
                console.log(msg);
            }
        }
    };

    pagination.init();

}(jQuery));
