export default class Loader {
    static create(loaderClass) {
        return $('<div>', {
            class: loaderClass.substring(1),
            html: $('<div>', {
                html: $('<div>').add('<div>')
            })
        });
    }

    static load(target, loaderClass = '.loader') {
        target.prepend(this.create(loaderClass));
    }

    static clear(loaderClass = '.loader'){
        $(loaderClass).remove();
    }
}
