'use strict';

var $wrapper = $('.product-configurator-wrapper');


$wrapper.on('click', '[data-action="read-more"]', function(e) {
    e.preventDefault();
    $(this).text('read-less');
    $(this).attr('data-action', 'read-less');
    $wrapper.find('.content-depending').fadeIn();
    $wrapper.find('.preview-image').css({ 'opacity': '0'});
    $wrapper.find('.preview-content').css({ 'opacity': '0'});
});

$wrapper.on('click', '[data-action="read-less"]', function(e) {
    e.preventDefault();
    $(this).text('read-more');
    $(this).attr('data-action', 'read-more');
    $wrapper.find('.content-depending').fadeOut(300, function() {
        $wrapper.find('.preview-image').css({ 'opacity': '1'});
        $wrapper.find('.preview-content').css({ 'opacity': '1'});
    });

});

$wrapper.on('click', '[data-action="close"]', function(e) {
    e.preventDefault();
    $wrapper.removeClass('active');

    $('[data-action="read-more"]').fadeIn();
    $wrapper.find('.content-depending').fadeOut(300, function() {
        $wrapper.find('.preview-image').css({ 'opacity': '1'});
        $wrapper.find('.preview-content').css({ 'opacity': '1'});
    });

});

