;(function ($, window, document, undefined) {

    var $slideUpelement = $('.jumbo-header--slider');

    setInterval(function () {

        $slideUpelement.find('.out').removeClass('out');

        if($slideUpelement.find('.active').next().length > 0) {
            $slideUpelement.find('.active').removeClass('active').addClass('out').next().addClass('active');
        } else {
            $slideUpelement.find('.active').removeClass('active').addClass('out');
            $('.jumbo-header--slider .item:first-child').addClass('active');
        }


    }, 5000);



})(window.Zepto || window.jQuery, window, document);
